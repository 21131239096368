import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery, useMutation } from "@apollo/client";
import DELETE_ITEM from "../../../../../../graphql/DELETE_RECORD";
import * as Routes from "../../../../../../routes";
import DataTable from "../../../../../../components/dataTable";
import DataTableSkeleton from "../../../../../../components/dataTable/skeleton";
import ActionCell from "../../../../../../components/dataTable/areas/suburbDefaultActionCell";
import { RootState } from "../../../../../../store/store";

import TableLayout from "../../../../../../components/layout/TableLayout";
import GET_SUBURB_DEFAULT_CONTENT from "../../../../../../graphql/areas/GET_SUBURB_DEFAULT_CONTENT";
import EmptyContentMessage from "../../../../../../components/layout/EmptyContentMessage";
import { table } from "console";

function City() {
  const params = useParams<SiteParams>();
  const state = useSelector((state: RootState) => state);

  const { loading, error, data } = useQuery(GET_SUBURB_DEFAULT_CONTENT, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  const suburbs: any = useMemo(() => {
    return data ? data.models.results : [];
  }, [data]);

  const [
    deleteItem,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(DELETE_ITEM, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const deletedItem = data?.record_delete; // Corrected field name
      if (!deletedItem) return;

      const existingItems: any = cache.readQuery({
        query: GET_SUBURB_DEFAULT_CONTENT,
      });

      if (existingItems && existingItems.models) {
        const updatedItems = existingItems.models.results.filter(
          (item: any) => item.id !== deletedItem.id
        );

        cache.writeQuery({
          query: GET_SUBURB_DEFAULT_CONTENT,
          data: {
            models: {
              ...existingItems.models,
              results: updatedItems,
            },
          },
        });
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleDelete = (id: string) => {
    deleteItem({
      variables: {
        id: id,
        table: "modules/areas/default_suburb_content",
      },
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "properties.name",
      },
      {
        Header: "Slug",
        accessor: "properties.slug",
      },
      {
        Header: "Created At",
        accessor: "created_at",
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <ActionCell
              site={params.site}
              value={props.value}
              siteURL={state.current_site.site?.url}
              slug={props.row.values.slug}
              handleDelete={handleDelete}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <TableLayout
      loading={loading}
      buttonLink={Routes.SUBURB_DEFAULT_CONTENT_CREATE_LINK(params.site)}
      tableHeading="Default Suburb/Town Content"
      buttonText="Add New Default Content"
    >
      {suburbs.length === 0 ? (
        loading ? (
          <DataTableSkeleton rows={15} columns={5} />
        ) : (
          <EmptyContentMessage
            message={
              "This instance does not have any default suburb/town content"
            }
          />
        )
      ) : (
        <DataTable data={suburbs} columns={columns} />
      )}
    </TableLayout>
  );
}

interface SiteParams {
  site: string;
}

export default City;
