import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery, useMutation } from "@apollo/client";

import DELETE_RECORD from "../../../../../graphql/DELETE_RECORD";
import GET_SUBURBS from "../../../../../graphql/areas/GET_SUBURBS";

import * as Routes from "../../../../../routes";
import DataTable from "../../../../../components/dataTable";
import ActionCell from "../../../../../components/dataTable/areas/suburbActionCell";
import { RootState } from "../../../../../store/store";
import TableLayout from "../../../../../components/layout/TableLayout";
import EmptyContentMessage from "../../../../../components/layout/EmptyContentMessage";

function City() {
  const params = useParams<SiteParams>();
  const state = useSelector((state: RootState) => state);

  const { loading, error, data } = useQuery(GET_SUBURBS, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
  });

  const pages: any = useMemo(() => {
    return data ? data.models.results : [];
  }, [data]);

  const [
    deleteSuburb,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(DELETE_RECORD, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const deletedItem = data?.record_delete;
      if (!deletedItem) return;

      const existingItems: any = cache.readQuery({ query: GET_SUBURBS });
      if (!existingItems || !existingItems.models) return;

      const updatedItems = existingItems.models.results.filter(
        (item: any) => item.id !== deletedItem.id
      );

      cache.writeQuery({
        query: GET_SUBURBS,
        data: {
          models: {
            ...existingItems.models,
            results: updatedItems,
          },
        },
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleDelete = (id: string) => {
    deleteSuburb({
      variables: {
        id: id,
        table: "modules/areas/suburb",
      },
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "properties.suburb",
      },
      {
        Header: "Slug",
        accessor: "properties.slug",
      },
      {
        Header: "Postcode",
        accessor: "properties.postcode",
      },
      {
        Header: "city",
        accessor: "city.properties.name",
      },
      {
        Header: "Services",
        accessor: "services",
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <>
              {props.value
                .map((service: any) => service.properties.name)
                .join(", ")}
            </>
          );
        },
      },
      {
        Header: "Created At",
        accessor: "created_at",
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
      },
      {
        Header: "Has Unique Content",
        accessor: "content",
        Cell: (props: any) => {
          return <>{props.value.length ? "true" : "false"}</>;
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <ActionCell
              site={params.site}
              value={props.value}
              siteURL={state.current_site.site?.url}
              slug={props.row.values.slug}
              handleDelete={handleDelete}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <TableLayout
      loading={loading}
      buttonLink={Routes.SUBURB_CREATE_LINK(params.site)}
      tableHeading="Suburb/Towns"
      buttonText="Add New Suburb or Town"
    >
      {pages.length === 0 ? (
        <EmptyContentMessage message="This instance does not have any suburbs or towns." />
      ) : (
        <DataTable data={pages} columns={columns} />
      )}
    </TableLayout>
  );
}

interface SiteParams {
  site: string;
}

export default City;
