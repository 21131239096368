import { gql } from "@apollo/client";

const DELETE_RECORD = gql`
  mutation delete_record($id: ID!, $table: String!) {
    record_delete(id: $id, table: $table) {
      id
    }
  }
`;

export default DELETE_RECORD;
