import React, { useMemo } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { Container, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useQuery, useMutation } from "@apollo/client";
import DELETE_RECORD from "../../../../../../graphql/DELETE_RECORD";
import * as Routes from "../../../../../../routes";
import DataTable from "../../../../../../components/dataTable";
import DataTableSkeleton from "../../../../../../components/dataTable/skeleton";
import ActionCell from "../../../../../../components/dataTable/areas/cityContentActionCell";
import { RootState } from "../../../../../../store/store";
import TableLayout from "../../../../../../components/layout/TableLayout";
import GET_CITY_CONTENT from "../../../../../../graphql/areas/GET_CITY_CONTENT";
import EmptyContentMessage from "../../../../../../components/layout/EmptyContentMessage";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

function City() {
  const params = useParams<SiteParams>();
  const state = useSelector((state: RootState) => state);

  const { loading, error, data } = useQuery(GET_CITY_CONTENT, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    variables: {
      city_id: Number(params.cityID),
    },
  });

  const cities: any = useMemo(() => {
    return data ? data.models.results : [];
  }, [data]);

  const [
    deleteCityContent,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(DELETE_RECORD, {
    context: {
      headers: {
        Authorization: `Bearer ${state.current_user.user?.token}`,
        instance: state.current_site.site?.slug,
      },
    },
    update: (cache, { data }) => {
      const deletedRecord = data?.record_delete;
      const existingRecords: any = cache.readQuery({
        query: GET_CITY_CONTENT,
        variables: {
          city_id: Number(params.cityID),
        },
      });

      if (deletedRecord && existingRecords) {
        const updatedRecords = existingRecords.models.results.filter(
          (record: any) => record.id !== deletedRecord.id
        );

        cache.writeQuery({
          query: GET_CITY_CONTENT,
          data: {
            models: {
              ...existingRecords.models,
              results: updatedRecords,
            },
          },
          variables: {
            city_id: Number(params.cityID),
          },
        });
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleDelete = (id: string) => {
    deleteCityContent({
      variables: {
        id: id,
        table: "modules/areas/city_content",
      },
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "properties.name",
      },
      {
        Header: "Slug",
        accessor: "properties.slug",
      },
      {
        Header: "Created At",
        accessor: "created_at",
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        disableFilters: true,
        Cell: (props: any) => {
          let date = new Date(props.value);
          return <>{date.toLocaleString("en-GB")}</>;
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          return (
            <ActionCell
              site={params.site}
              city={params.cityID}
              value={props.value}
              siteURL={state.current_site.site?.url}
              slug={props.row.values.slug}
              handleDelete={handleDelete}
            />
          );
        },
      },
    ],
    []
  );

  return (
    <Container className="removePadding">
      <Button
        className="marginBottom"
        component={RouterLink}
        startIcon={<KeyboardBackspaceIcon />}
        to={Routes.CITY_LINK(params.site)}
      >
        Back
      </Button>

      <TableLayout
        loading={loading}
        buttonLink={Routes.CITY_CONTENT_CREATE_LINK(params.site, params.cityID)}
        tableHeading="Service Area Content"
        buttonText="Add New Service Area Content"
      >
        {cities.length === 0 ? (
          loading ? (
            <DataTableSkeleton rows={15} columns={5} />
          ) : (
            <EmptyContentMessage
              message={"This instance does not have any service area content"}
            />
          )
        ) : (
          <DataTable data={cities} columns={columns} />
        )}
      </TableLayout>
    </Container>
  );
}

interface SiteParams {
  site: string;
  cityID: string;
}

export default City;
